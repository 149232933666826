<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ข้อมูลผู้ใช้
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="4">
          <div class="primary--text form-title mb-5">ข้อมูลส่วนตัว</div>
          <div class="pl-10">
            <v-text-field
              name="name"
              v-model="formSubmit.name"
              label="ชื่อ นามสกุล"
              outlined
              dense
              background-color="white"
              :error-messages="$v.formSubmit.name.$error ? $error_text : ''"
            />

            <v-text-field
              name="username"
              v-model="formSubmit.username"
              :error-messages="$v.formSubmit.username.$error ? $error_text : ''"
              label="Username"
              v-on:keypress="isLetter($event)"
              outlined
              dense
              background-color="white"
            />
            <v-select
              v-model="formSubmit.permission.branchs"
              :error-messages="
                $v.formSubmit.permission.branchs.$error ? $error_text : ''
              "
              :items="list.branchs"
              item-value="_id"
              item-text="branch_name"
              chips
              small-chips
              label="สาขา"
              multiple
              dense
              outlined
              name="branch"
            ></v-select>
            <v-select
              name="user_group"
              label="กลุ่มผู้ใช้"
              item-value="_id"
              item-text="group_name"
              outlined
              dense
              background-color="white"
              :items="list.user_groups"
              v-model="formSubmit.permission.user_group"
              :error-messages="
                $v.formSubmit.permission.user_group.$error ? $error_text : ''
              "
            ></v-select>
            <v-text-field
              name="phone_number"
              v-model="formSubmit.phone_number"
              label="เบอร์ติดต่อ (ถ้าเป็นพนักงาน ไม่จำเป็นต้องใส่)"
              outlined
              dense
              background-color="white"
            />
            <v-text-field
              name="email"
              v-model="formSubmit.email"
              label="อีเมล (ถ้าเป็นพนักงาน ไม่จำเป็นต้องใส่)"
              outlined
              dense
              background-color="white"
              :readonly="id != null"
            />
            <!-- <v-text-field
              name="identification_number"
              v-model="formSubmit.identification_number"
              label="เลขบัตรประชาชน"
              outlined
              dense
              background-color="white"
            />
            <DatePicker
              label="วันเดือนปีเกิด"
              :value="formSubmit.birthday"
              @onChange="val => (formSubmit.birthday = val)"
              :max="maxDate"
              :min="minDate"
            /> -->
          </div>
        </v-col>

        <v-col cols="4" offset="1">
          <!-- <div class="primary--text form-title mb-5">ลายเซ็นอิเล็กทรอนิกส์</div>
          <div class="pl-10">
            <div>
              แนบไฟล์ลายเซ็น
              <span class="info--text pointer" @click="clearSignature()">ล้าง</span>
            </div>
            <uploadImage
              directory="logo"
              :limit="2"
              :src="formSubmit.e_signature_url"
              @onChangeUrl="formSubmit.e_signature_url = $event"
            >
              <v-img
                :src="
                  formSubmit.e_signature_url ||
                    'http://via.placeholder.com/320x100?text=Signature'
                "
                width="320px"
                height="100px"
                id="signature"
              />
            </uploadImage>
          </div> -->
          <div class="primary--text form-title mb-5">แก้ไขรหัสผ่าน</div>
          <div class="pl-10">
            <!-- <v-text-field
              v-if="isUpdate"
              name=""
              label="รหัสผ่านเก่า"
              outlined
              dense
              background-color="white"
              type="password"
            /> -->
            <v-text-field
              name=""
              label="รหัสผ่านใหม่"
              v-on:keypress="isLetter($event)"
              outlined
              dense
              background-color="white"
              v-model="formSubmit.password"
            />
            <!-- :error-messages="$v.formSubmit.password.$error ? $error_text : ''" -->
            <v-text-field
              name=""
              label="ยืนยันรหัสผ่านใหม่"
              v-on:keypress="isLetter($event)"
              outlined
              dense
              background-color="white"
              v-model="form.confirm_password"
            />
            <!-- :error-messages="$v.form.confirm_password.$error ? 'รหัสผ่านไม่ตรงกัน' : ''" -->
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click.prevent="save()"
        :loading="loading"
        :disabled="loading"
      >
        บันทึก
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from 'moment';
import DatePicker from '@/components/DatePicker.vue';
import uploadImage from '@/components/uploadImage.vue';

import {
  required,
  sameAs,
  checked,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';

export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },
  components: {
    DatePicker,
    uploadImage
  },
  validations: {
    formSubmit: {
      name: { required },
      phone_number: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(10)
      },
      username: { required },
      permission: {
        branchs: { required },
        user_group: { required }
      },
      identification_number: {
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13)
      },
      password: {
        required
      }
    },
    form: {
      confirm_password: {
        sameAsPassword: sameAs(function() {
          return this.formSubmit.password != '' ? this.formSubmit.password : '';
        })
      }
    }
  },
  watch: {
    id: function(newVal, oldVal) {
      console.log(newVal);
      this.$v.formSubmit.$reset();
      this.$v.form.$reset();
      this.getData(newVal);
    }
  },
  mounted() {
    this.getData(this.id);
    this.getBranchs();
    this.getUserGroups();
  },
  data() {
    return {
      maxDate: moment()
        .add(-1, 'days')
        .format('YYYY-MM-DD'),
      minDate: moment()
        .add(-100, 'years')
        .format('YYYY-MM-DD'),
      modal: false,
      loading: false,
      options: {
        penColor: '#000000'
      },
      formSubmit: {
        user_id: 0,
        name: '',
        phone_number: '',
        username: '',
        email: '',
        identification_number: '',
        birthday: moment()
          .add(-1, 'days')
          .format('YYYY-MM-DD'),
        e_signature_url: '',
        password: '',
        permission: {
          branchs: [],
          user_group: ''
        }
      },
      form: {
        confirm_password: ''
      },
      list: {
        user_groups: [],
        branchs: []
      },
      rulesName: [value => !!value || 'กรุณากรอกข้อมูล'],
      rulesUsername: [value => !!value || 'กรุณากรอกข้อมูล.']
    };
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    onChangeDate(val) {
      this.formSubmit.birthday = moment(val).format('YYYY-MM-DD');
    },
    getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true })
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async res => {
          this.list.branchs = res.result;
        });
    },
    getUserGroups() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true })
      };
      this.$axios
        .post(`${this.$baseUrl}/user_group/get_user_group_list`, body)
        .then(async res => {
          this.list.user_groups = res.result;
        });
    },
    close() {
      this.$v.formSubmit.$reset();
      this.$v.form.$reset();

      this.$v.formSubmit.$reset();
      this.$v.form.$reset();

      this.$emit('onClose');
    },

    async getData(id) {
      if (id) {
        let body = {
          token: this.$jwt.sign({ user_id: this.id }, this.$privateKey, {
            noTimestamp: true
          })
        };
        await this.$axios
          .post(`${this.$baseUrl}/users/get_by_id`, body)
          .then(res => {
            this.formSubmit = { ...res.result };
            this.formSubmit.password = '';

            if (!this.formSubmit.birthday) {
              this.formSubmit.birthday = moment()
                .add(-1, 'days')
                .format('YYYY-MM-DD');
            }
          })
          .catch(err => {
            console.log('err', err);
          });
        this.loading = false;
      } else {
        this.form = {
          confirm_password: ''
        };
        this.formSubmit = {
          user_id: 0,
          name: '',
          phone_number: '',
          username: '',
          email: '',
          identification_number: '',
          birthday: moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD'),
          e_signature_url: '',
          password: '',
          permission: {
            branchs: [],
            user_group: ''
          }
        };
        this.formSubmit = {
          user_id: 0,
          name: '',
          phone_number: '',
          username: '',
          email: '',
          identification_number: '',
          birthday: moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD'),
          e_signature_url: '',
          password: '',
          permission: {
            branchs: [],
            user_group: ''
          }
        };
      }
    },
    async save() {
      this.$v.form.$touch();
      this.$v.formSubmit.$touch();
      // if (this.$v.form.$invalid || this.$v.formSubmit.$invalid) return;

      await this.$alertConfirm({
        title: `ยืนยันบันทึกข้อมูล ?`,
        text: ``
      }).then(async result => {
        if (result.isConfirmed) {
          if (this.formSubmit.password != this.form.confirm_password) {
            this.$alertServerError({ title: 'การยืนยันรหัสผ่านไม่ตรงกัน' });
            return;
          }
          if (this.birthday == '') this.formSubmit.birthday = '';
          else this.formSubmit.birthday = this.formSubmit.birthday; //'`${this.formSubmit.birthday}T00:00:00`

          let subPath = 'create_staff_user';
          if (this.isUpdate) {
            subPath = 'edit_user';
            this.formSubmit = {
              user_id: this.formSubmit._id,
              ...this.formSubmit
            };
            delete this.formSubmit._id;
          }
          console.log(this.formSubmit)
          let body = {
            token: this.$jwt.sign(this.formSubmit, this.$privateKey, {
              noTimestamp: true
            })
          };
          await this.$axios
            .post(`${this.$baseUrl}/users/${subPath}`, body)
            .then(res => {
              if (res.status == 'success') {
                this.$alertSuccess({ title: res.status });
              } else {
                this.$alertServerError({ title: res.status });
              }
            })
            .catch(err => {
              console.log('err', err);
              this.$alertServerError({ title: err.error_message });
            });

          this.form.confirm_password = '';
          this.formSubmit.password = '';
          // this.formSubmit.name = ""
          // this.formSubmit.phone_number = ""
          // this.formSubmit.username = ""
          // this.formSubmit.permission.branchs = ""
          // this.formSubmit.permission.user_group = ""
          this.$emit('onSave');
        }
        this.loading = false;

        this.formSubmit.confirm_password = '';

        this.form.confirm_password = '';

        this.formSubmit.password = '';
        // this.formSubmit.name = ""
        // this.formSubmit.phone_number = ""
        // this.formSubmit.username = ""
        // this.formSubmit.permission.branchs = ""
        // this.formSubmit.permission.user_group = ""
        this.$emit('onSave');

        this.loading = false;
        this.getData(false);
      });
    }
  },
  computed: {
    isUpdate() {
      return this.id;
    }
  }
};
</script>

<style lang="scss" scoped>
#signature {
  border: 1px solid #000;
  background-color: white;
}
</style>
